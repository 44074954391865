<!--
 * @Author: your name
 * @Date: 2021-04-15 16:35:09
 * @LastEditTime: 2021-04-15 18:55:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\components\huakuai\index.vue
-->
<template>
    <div>
        <div class="verify-wrap" id="verify-wrap"></div>
    </div>
</template>
<script>
import huakuaijs from "../../../util/huakuai"
export default {
    mounted(){
        var that = this;
        var SlideVerifyPlug = huakuaijs;
			var slideVerify = new SlideVerifyPlug('#verify-wrap',{
				wrapWidth:'6rem',//设置 容器的宽度 ，默认为 350 ，也可不用设，你自己css 定义好也可以，插件里面会取一次这个 容器的宽度
	            initText:'请按住滑块，拖动到最右边',  //设置  初始的 显示文字
	            sucessText:'短信发送成功',//设置 验证通过 显示的文字
	            getSucessState:function(res){
	           		//当验证完成的时候 会 返回 res 值 true，只留了这个应该够用了 
	           		that.$emit("para",res)
	           	}
			});
			$("#resetBtn").on('click',function(){
				slideVerify.resetVerify();//可以重置 插件 回到初始状态 
			})
			$("#getState").on('click',function(){
				alert(slideVerify.slideFinishState);   //这个可以拿到 当前验证状态  是否完成
			})
    }
}
</script>
<style>
.verify-wrap{
    height: 1rem;
    background-color: #e5e5e5;
    border: 1px solid #e0e0e0;
    margin: .3rem auto;
    position: relative;
}
.verify-wrap .drag-progress {
    position: absolute;
    left: 0;
    top: -1px;
    height: 1rem;
    border-top: 1px solid #7ac23c;
    border-top: 1px solid #7ac23c;
    width: 0;
    background-color: #7ac23c;
    color: #fff;
    font-size: .2rem;
    text-align: center;
    line-height: .4rem;
}
.verify-wrap .suc-drag-btn {
    background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAiCAYAAAApkEs2AAACA0lEQVRYhe2Y2yuDYRzHv7PJYc5zKspYSRMymStXu5NCs5W4csEfwJ+gSA53csg1F4R/gSu0OcWSmNIQkfMcEvu9tdpY79793leo91Nbbft993x6nj2/52kal8v1gX+Ajp4sFstve4jidrsR99sSUlFFlUYVVRpVVGl0SnyJ934VaxczOLpdwd3bufBeWnw+StLrYc1tQ3FqnewxZIk+vz9g7rAHO9dL3z67efXBfTkrPCqymtBqGkaiNoU9FnvpSXJyzx5R8itUQ7WU4cIWnT/qhe9xS3I91VKGC0uUfpPbV4uSajUhQ1CGshxYorRxpGDObECf1Ysm40DM2a+wNhPt7mhUZzvhMI1Aq9HBkFgUUzYSrBkNtiDCkGBES/Eg8pLKwiSdplFB8uRhEzMH3RGzsSC7j9oKe1CT4wi0oEZMeRzITy4XJOM0WkFy2uOE//1O7jA8UWrm1CeJ5dMxlGXYoI/PQrd5AQlavagkZTmwlt4UOHGCnPs9wkw+vl0jSZcWdSZDsz8uas1tD3t99rSL8b0W3Lz4cHy/LrrctYEjlQNr6Y2ptag0NIf10gv/Pvo3akRzlOGe++yTyV4yhAJ9leR6qqUMF7YoXTC6zPPChSMaVEO1ci4lstoTDdxROhE4Fjv/9jUvCIkoISPGv7nhq6JKo4oqjSqqNEIfpf8f/zqf+36643RPeo8AAAAASUVORK5CYII=) no-repeat center center;
    background-size: 100% 100%;
}

.verify-wrap .drag-btn {
    position: absolute;
    left: -1px;
    top: -1px;
    width: 1rem;
    height: 1.03rem;
    background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAiCAYAAAApkEs2AAAA/UlEQVRYhe3XLc9GYBjG8eN6dkdFIQuqoCiCbj6ob2CCIplRaJKqEHT3c/kAz66Xc/fLnvO3UY7N/jNmxDAMF77AQ57iOH53x5/GccTPuyNUcSg1DqXGodQ4lNr/CC3LEsuyaG8mrEKzLEPXdZjnWWszYRXq+z7yPMc0Tej7XnkzYf2Muq6LoiiwrivatsV1XUrby0Mlx3Huu7fvO5qmUd50kL31QgijTdXD+gq/zvNEVVXwPA9pmipvOqxDj+O4Q4IgQJIkypsuq9Bt21DXNaIoug/VzYRVqHw55J0Kw1BrMyHkXyj/3BHiUGocSo1DqXEota8Jvb/18hP16Z7qL3h/w53n4AAAAABJRU5ErkJggg==) no-repeat center center;
    background-size: 100% 100%;
    z-index: 2;
    cursor: move;
}
.verify-wrap .fix-tips, .verify-msg {
    width: 100%;
    position: absolute;
    right: 0;
    left: 1px;
    height: 100%;
    color: #51555c;
    z-index: 1;
    line-height: 1rem;
    font-size: .2rem;
    text-align: center;
}
.verify-wrap .verify-msg {
    background-color: #7ac23c;
    color: #fff;
    display: none;
}
.verify-wrap .fix-tips, .verify-msg {
    width: 100%;
    position: absolute;
    right: 0;
    left: 1px;
    height: 100%;
    color: #51555c;
    z-index: 1;
    line-height: 1rem;
    font-size: .2rem;
    text-align: center;
}
</style>