<template>
  <div v-wechat-title="$route.meta.title">
    <div v-if="ismsk" class="msk"></div>
    <div v-if="ismsk" class="popups">
      <div>
        <div></div>
        <div><img @click="ismsk = false" src="../../assets/delimg.png" /></div>
      </div>
      <p>{{ msg == undefined ? "您是否忍心拒绝授权？（请和委托方确认后操作）" : msg }}</p>
      <div>
        <div v-if="msg==undefined">
          <button @click="cliok" id="btqone">{{ okmsg }}</button>
          <button @click="clino" id="btqtwo">{{ nomsg }}</button>
        </div>

        <div v-else>
          <button @click="ok" id="btqone">{{ okmsg }}</button>
          <button @click="no" id="btqtwo">{{ nomsg }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      okmsg: "确认",
      nomsg: "取消",
    };
  },
  props: {
    ismsk: Boolean, //是否显示遮罩层
    msg: String, //页面传来的值
  },
  methods: {
    cliok() {
      if (this.okmsg == "残忍拒绝") {
        this.$emit("ok","")
      }
      this.okmsg = "残忍拒绝";
      this.nomsg = "同意授权";
    },
    clino() {
      this.$emit("modifyismsk", this.ismsk);
    },
    ok(){
      this.$emit("ok","")
    },
    no(){
      this.$emit("no","")
    },
  },
};
</script>
<style scoped>
.popups {
  z-index: 1100;
  position: fixed;
  background-color: #ffffff;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  overflow: hidden;
}
.popups p {
  margin: 0 0.69rem;
  min-width: 3.05rem;
  margin-bottom: 0.64rem;
  text-align: center;
  font-size: 0.3rem;
  color: #000000;
}
.popups > div:first-child {
  display: flex;
  justify-content: space-between;
  margin: 0.34rem 0.33rem 0.22rem 0;
}
.popups > div:first-child img {
  width: 0.34rem;
}
#btqone,
#btqtwo {
  width: 50%;
  margin: 0;
  border: none;
  padding: 0.19rem 0;
}
#btqone {
  background-color: #f65a16;
  color: #ffffff;
}
#btqtwo {
  background-color: #dcdcdc;
}
</style>